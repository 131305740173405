import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

// Components
import PulseLoader from 'hsi/components/PulseLoader';
import SearchResults from '../ExportSearchResults';
import {AuthDialog} from './AuthDialog';
import SharedDashboardSidebar from './SharedDashboardSidebar';

// Hooks
import {useAppDispatch, useAppSelector} from 'hsi/hooks/useRedux';

// Redux
import {
    selectCurrentSnapshot,
    selectSharedDashboardStatus,
    selectSnapshots,
} from 'hsi/slices/sharedDashboard/selectors';
import {getSnapshot} from 'hsi/slices/sharedDashboard/thunks';

// Types
import type {SharedDashboardURLParams} from './types';

// Utils
import {isValidUUID} from 'hsi/utils/uuid';
import {T} from 'hsi/i18n';

// Styles
import useStyles from './styles';

export default function SharedDashboard() {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const {snapshotId} = useParams<SharedDashboardURLParams>();
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const snapshots = useAppSelector(selectSnapshots);
    const currentReport = useAppSelector(selectCurrentSnapshot);
    const sharedDashboardStatus = useAppSelector(selectSharedDashboardStatus);

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(getSnapshot({snapshotId}));
        }
    }, [isAuthenticated, dispatch, snapshotId]);

    if (!snapshotId || !isValidUUID(snapshotId)) {
        return (
            <div className={classes.errorContainer}>
                <span>{T('sharedDashboard.error.invalidSnapshot')}</span>
            </div>
        );
    }

    if (!isAuthenticated) {
        return (
            <AuthDialog snapshotId={snapshotId} onAuthenticated={() => setIsAuthenticated(true)} />
        );
    }

    return (
        <div className={classes.container}>
            {snapshots && (
                <SharedDashboardSidebar currentSnapshotId={snapshotId} snapshots={snapshots} />
            )}
            <div className={classes.snapshotContainer}>
                {sharedDashboardStatus === 'failed' && (
                    <span>{T('sharedDashboard.error.loadFailed')}</span>
                )}
                {sharedDashboardStatus === 'loading' && <PulseLoader />}
                {sharedDashboardStatus === 'succeeded' && currentReport && <SearchResults />}
            </div>
        </div>
    );
}
