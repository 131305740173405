import cn from 'classnames';
import capitalize from 'lodash/capitalize';
import {MutableRefObject, memo, useCallback, useEffect, useMemo, useRef} from 'react';

// Components
import Button from 'hsi/components/Button';
import ChipMultipleSelect from 'hsi/components/ChipMultipleSelect';
import {Dialog, DialogActions, DialogContent} from 'hsi/components/Dialog';
import PulseLoader from 'hsi/components/PulseLoader';
import Annotation from './Annotation';
import GeneratePasswordPage from './GeneratePasswordPage';
import ItemWithHeader from './ItemWithHeader';
import ReportList from './ReportList';

// Hooks
import useConfig from 'hsi/hooks/useConfig';
import useDates from 'hsi/hooks/useDates';
import useFlags from 'hsi/hooks/useFlags';
import useGetCards from 'hsi/hooks/useGetCards';
import {VisibleContainer} from 'hsi/hooks/useOnVisible';
import {useAppDispatch, useAppSelector} from 'hsi/hooks/useRedux';
import {useAnnotations} from './hooks/useAnnotations';
import {useCardSelection} from './hooks/useCardSelection';
import {useExportReportsModal} from './hooks/useExportReportsModal';

// Actions
import {showNotification} from 'hsi/actions/notificationsActions';

// Contexts
import {IsCardInteractivityDisabled} from 'hsi/contexts/IsCardInteractivityDisabled';

// Utils
import {formatCardTitleAsString} from 'hsi/components/Card/CardTitle';
import {cardHasAggregate, useGetCurQueryCardAggregates} from 'hsi/utils/cards/aggregates';
import {cardHasBreakdown, useGetCurQueryCardBreakdowns} from 'hsi/utils/cards/breakdowns';
import {getRelativePos} from 'hsi/utils/scroll';

// Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

//Types
import {CardComponentConfig} from 'hsi/types/cards';
import {AnnotationKey, ChartKey} from 'hsi/types/charts';

export type ReportModalSections = 'createReport' | 'passwordGenerator' | 'viewReports' | undefined;

type ExportWithNotesModalProps = {
    handleClose: () => void;
    isMultipleSearch?: boolean;
    isOpen: boolean;
    openWithSection: ReportModalSections;
    projectId: number;
    savedSearchId: number;
    savedSearchName: string;
};

// Consts
const visibleContainerOptions = {margin: 250};
const MAX_LENGTH = 1000;

const ExportWithNotesModal = memo(
    ({
        handleClose,
        isOpen,
        openWithSection,
        savedSearchId,
        savedSearchName,
        projectId,
    }: ExportWithNotesModalProps) => {
        const classes = useStyles();
        const {
            searchResults: {cardRules, cardHeights},
            appSource,
        } = useConfig();

        const flags = useFlags();
        const {isSharableDashboardsEnabled} = flags;
        const {formatTo} = useDates();
        const dispatch = useAppDispatch();

        const {annotations: initialAnnotations} = useAppSelector((state) => state.pdfExport);
        const dateRange = useAppSelector((state) => state.filters.dateRange);

        // Cards
        const _cards = useGetCards({cardRules, cardHeights, ignoreHidden: true});
        const cardBreakdowns = useGetCurQueryCardBreakdowns();
        const cardAggregates = useGetCurQueryCardAggregates();
        const charts = useAppSelector((state) => state.chart);

        const {
            selectedCards,
            cardPickOptions,
            selectedCardPickOptions,
            saveCardsToExport,
            deleteHandler,
            selectCardsConfig,
            selectedCardsLoaded,
        } = useCardSelection(savedSearchId, _cards, charts, cardBreakdowns, cardAggregates);

        // Annotations
        const {
            annotationsRef,
            hasError,
            hasAnyAnnotations,
            setAnnotation,
            clearAnnotations,
            setName,
            nameRef,
        } = useAnnotations(savedSearchId, savedSearchName, initialAnnotations, MAX_LENGTH);

        // Modal Logic
        const {
            modalSection,
            snapshots,
            password,
            setPassword,
            isPasswordValid,
            setIsPasswordValid,
            enableSharedDashboardsForQueryHandler,
            exportAsPDFHandler,
            createReportHandler,
            onClose,
            loading,
            error,
        } = useExportReportsModal({
            savedSearchId,
            projectId,
            flags,
            appSource,
            openWithSection,
            handleClose,
            nameRef,
        });

        // Display error toast on API error.
        useEffect(() => {
            if (error) {
                dispatch(
                    showNotification({
                        message: error,
                        hidable: true,
                        variant: 'warning',
                    }),
                );
            }
        }, [dispatch, error]);

        // Refs
        const elementsRef = useRef<Partial<Record<ChartKey, HTMLDivElement | null>> | null>(null);
        const modalAreaRef = useRef<HTMLDivElement | null>(null);

        const scrollHandler = useCallback(
            (event: React.UIEvent<HTMLElement>, cardName: ChartKey) => {
                event.stopPropagation();
                if (modalAreaRef?.current && elementsRef?.current?.[cardName]) {
                    modalAreaRef.current.scrollTop = getRelativePos(
                        elementsRef.current[cardName]!,
                        modalAreaRef.current,
                    ).top;
                }
            },
            [],
        );

        const cardSections = useMemo(
            () =>
                selectCardsConfig.map((card) => {
                    return (
                        <AnnotatedCard
                            key={card.name}
                            card={card}
                            setAnnotation={setAnnotation}
                            annotationsRef={annotationsRef}
                            elementsRef={elementsRef}
                            breakdown={
                                cardHasBreakdown(card.name)
                                    ? cardBreakdowns?.[card.name]
                                    : undefined
                            }
                            aggregate={
                                cardHasAggregate(card.name)
                                    ? cardAggregates?.[card.name]
                                    : undefined
                            }
                            classes={classes}
                        />
                    );
                }),
            [
                annotationsRef,
                cardAggregates,
                cardBreakdowns,
                classes,
                selectCardsConfig,
                setAnnotation,
            ],
        );

        const annotationsForm = useMemo(
            () => (
                <>
                    <Annotation
                        label={
                            <>
                                <span aria-hidden="true">
                                    {capitalize(T('exportToPDF.annotations.name'))}
                                </span>
                                <span className="offscreen">
                                    {T('exportToPDF.annotations.nameLabel')}
                                </span>
                            </>
                        }
                        maxLength={200}
                        multiline={false}
                        name="name"
                        placeholder={T('exportToPDF.annotations.namePlaceholder')}
                        setValue={setName}
                        valuesRef={nameRef}
                    />

                    <ItemWithHeader
                        header={T('exportToPDF.annotations.date')}
                        data-testid="exportHeaderDates"
                    >
                        <span className={classes.dateRange}>
                            <time
                                className={classes.time}
                                dateTime={formatTo(dateRange.startDate, 'yyyy-LL-ddZZ')}
                            >
                                {formatTo(dateRange.startDate, 'DDD')}
                            </time>{' '}
                            -{' '}
                            <time
                                className={classes.time}
                                dateTime={formatTo(dateRange.startDate, 'yyyy-LL-ddZZ')}
                            >
                                {formatTo(dateRange.endDate, 'DDD')}
                            </time>{' '}
                            {`(${formatTo(dateRange.endDate, 'ZZZZ')})`}
                        </span>
                    </ItemWithHeader>

                    <Annotation
                        clearLabel={
                            <>
                                <span aria-hidden="true">{T('exportToPDF.annotations.clear')}</span>
                                <span className="offscreen">
                                    {T('exportToPDF.annotations.clearSummaryAccessible')}
                                </span>
                            </>
                        }
                        label={
                            <>
                                <span aria-hidden="true">
                                    {capitalize(T('exportToPDF.annotations.summary'))}
                                </span>
                                <span className="offscreen">
                                    {T('exportToPDF.annotations.summaryLabel')}
                                </span>
                            </>
                        }
                        maxLength={MAX_LENGTH}
                        name="summary"
                        placeholder={T('exportToPDF.annotations.summaryPlaceholder')}
                        setValue={setAnnotation}
                        valuesRef={annotationsRef}
                    />

                    <ItemWithHeader header={T('exportToPDF.annotations.cardsSelector')}>
                        <ChipMultipleSelect
                            applyHandler={saveCardsToExport}
                            btnLabel="exportToPDF.cardSelectLbl"
                            chipsLbl="exportToPDF.selectedCards"
                            deleteHandler={deleteHandler}
                            options={cardPickOptions}
                            placeholder={T('exportToPDF.annotations.byDefault')}
                            selectedOptions={selectedCardPickOptions}
                            scrollHandler={scrollHandler}
                        />
                    </ItemWithHeader>

                    {cardSections}
                </>
            ),
            [
                annotationsRef,
                cardPickOptions,
                cardSections,
                classes.dateRange,
                classes.time,
                dateRange.endDate,
                dateRange.startDate,
                deleteHandler,
                formatTo,
                nameRef,
                saveCardsToExport,
                scrollHandler,
                selectedCardPickOptions,
                setAnnotation,
                setName,
            ],
        );

        if (!isOpen) return null;

        return (
            <Dialog
                className={cn(
                    classes.createPDFExport,
                    modalSection === 'passwordGenerator' ? classes.passwordGeneratorDialog : {},
                )}
                onClose={onClose}
                open={isOpen}
                title={T('exportToPDF.annotations.modalTitle')}
            >
                <VisibleContainer options={visibleContainerOptions}>
                    <DialogContent className={classes.content} ref={modalAreaRef}>
                        {modalSection === 'createReport' && annotationsForm}
                        {modalSection === 'passwordGenerator' && (
                            <GeneratePasswordPage
                                setIsPasswordValid={setIsPasswordValid}
                                password={password}
                                handlePasswordChange={setPassword}
                                onEnterHandler={enableSharedDashboardsForQueryHandler}
                            />
                        )}
                        {modalSection === 'viewReports' && (
                            <ReportList
                                snapshots={snapshots}
                                showSuccessBanner={openWithSection !== 'viewReports'}
                                loading={loading}
                            />
                        )}
                    </DialogContent>
                </VisibleContainer>
                <DialogActions>
                    {/* Create Report CTAs */}
                    {hasAnyAnnotations && modalSection === 'createReport' && (
                        <Button
                            priority="primary"
                            onClick={() => clearAnnotations()}
                            className={classes.clear}
                        >
                            {T('exportToPDF.annotations.clearAll')}
                        </Button>
                    )}

                    {/* Shareable dashboard CTAs */}
                    {isSharableDashboardsEnabled && modalSection === 'createReport' && (
                        <>
                            <Button
                                priority="primary"
                                className={classes.primaryCta}
                                onClick={() =>
                                    exportAsPDFHandler(selectedCards, annotationsRef.current!)
                                }
                                disabled={!selectedCardsLoaded || !!hasError || loading}
                            >
                                {!selectedCardsLoaded ? (
                                    <PulseLoader />
                                ) : (
                                    T('exportToPDF.exportAsPDF')
                                )}
                            </Button>
                            <Button
                                priority="cta"
                                onClick={() =>
                                    createReportHandler(selectedCards, annotationsRef.current!)
                                }
                                disabled={!selectedCardsLoaded || !!hasError || loading}
                            >
                                {!selectedCardsLoaded || loading ? (
                                    <PulseLoader />
                                ) : (
                                    T('exportToPDF.createReport')
                                )}
                            </Button>
                        </>
                    )}
                    {/* Use old CTAs when isSharableDashboardsEnabled is not enabled  */}
                    {!isSharableDashboardsEnabled && (
                        <Button
                            priority="cta"
                            onClick={() =>
                                exportAsPDFHandler(selectedCards, annotationsRef.current!)
                            }
                            disabled={!selectedCardsLoaded || !!hasError}
                        >
                            {selectedCardsLoaded ? T('exportToPDF.exportAsPDF') : <PulseLoader />}
                        </Button>
                    )}

                    {/* Password Generator CTAs */}
                    {modalSection === 'passwordGenerator' && (
                        <Button
                            priority="cta"
                            onClick={enableSharedDashboardsForQueryHandler}
                            disabled={!isPasswordValid}
                        >
                            {loading ? <PulseLoader /> : T('exportReports.save')}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        );
    },
);

export default ExportWithNotesModal;

type AnnotatedCardProps = {
    card: CardComponentConfig;
    setAnnotation: (name: AnnotationKey, value: string | undefined) => void;
    annotationsRef: MutableRefObject<Partial<Record<AnnotationKey, string | undefined>> | null>;
    elementsRef: MutableRefObject<Partial<Record<ChartKey, HTMLDivElement | null>> | null>;
    breakdown: string | undefined; //Partial<Record<ChartKey, string>>;
    aggregate: string | undefined;
    classes: ReturnType<typeof useStyles>;
};

const AnnotatedCard = memo(function AnnotatedCard({
    card,
    setAnnotation,
    annotationsRef,
    elementsRef,
    breakdown,
    aggregate,
    classes,
}: AnnotatedCardProps) {
    const cardTitle = formatCardTitleAsString(card.title, breakdown, aggregate).toLowerCase();

    return (
        <section
            className={classes.cardSection}
            key={card.name}
            data-testid={`${card.name}-section`}
        >
            <div
                ref={(element) => {
                    if (elementsRef?.current?.[card.name]) {
                        elementsRef.current[card.name] = element;
                    }
                }}
                className={cn(classes.cardWrapper, 'printMedia')}
            >
                <IsCardInteractivityDisabled.Provider value={true}>
                    <card.component height={card.height} title={card.title} renderWhenVisible />
                </IsCardInteractivityDisabled.Provider>
            </div>
            <Annotation
                clearLabel={
                    <>
                        <span aria-hidden="true">{T('exportToPDF.annotations.clear')}</span>
                        <span className="offscreen">
                            {T('exportToPDF.annotations.clearAccessible', {cardTitle})}
                        </span>
                    </>
                }
                label={
                    <>
                        <span aria-hidden="true">
                            {capitalize(T('exportToPDF.annotations.notes'))}
                        </span>
                        <span className="offscreen">
                            {T('exportToPDF.annotations.notesLabel', {cardTitle})}
                        </span>
                    </>
                }
                maxLength={MAX_LENGTH}
                name={card.name}
                setValue={setAnnotation}
                valuesRef={annotationsRef}
            />
        </section>
    );
});
