import http from 'hsi/classes/Http';
import {ApiResponse} from 'hsi/types/apiv2';

import {SnapshotData, SnapshotList} from 'hsi/types/snapshots';

export async function addSharedDashboards(projectId: number, queryId: number, password: string) {
    return http.post(`/apiv2/${projectId}/saved-search/${queryId}/shared-dashboard`, {password});
}

export async function editSharedDashboards() {
    //TODO
}

export async function deleteSharedDashboards(projectId: number, queryId: number) {
    //TODO
}

//snapshots

export async function getSharedDashboardSnapshots(projectId: number, queryId: number) {
    return http.get<ApiResponse<SnapshotList>>(
        `/apiv2/${projectId}/saved-search/${queryId}/shared-dashboard`,
    );
}

export async function getSharedDashboardSnapshotById(
    projectId: number,
    queryId: number,
    snapshotId: string,
) {
    return http.get(
        `/apiv2/${projectId}/saved-search/${queryId}/shared-dashboard/snapshots/${snapshotId}`,
    );
}

export async function addSharedDashboardSnapshot(
    projectId: number,
    queryId: number,
    values: {name: string; dataVersion: number; data: SnapshotData},
) {
    return http.post(
        `/apiv2/${projectId}/saved-search/${queryId}/shared-dashboard/snapshots`,
        values,
    );
}

export async function editSharedDashboardSnapshot() {
    //TODO
}

export async function deleteSharedDashboardSnapshot(projectId: number, snapshotId: string) {
    //TODO
}
