import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
    container: {
        display: 'flex',
        width: '100vw',
        height: '100vh',
    },
    errorContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    snapshotContainer: {
        margin: 'auto',
    },
}));
